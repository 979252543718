import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import 'normalize.css';

import Nav from './Nav';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import 'animate.css';
import { animateCSS } from '../utils/animateHelper';

//import bg from '../assets/images/BG/fondo.png'


const SiteBorderStyles = styled.div`
    position:relative;
   /* background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
    z-index: 0;*/
    overflow-x: hidden;

    .parallax-container {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      filter: opacity(0.3);
        .img-wrapper, .img-wrapper-base {
            position: absolute;
            top: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
        }

        .img-wrapper-base {
          z-index: 0;
          .gatsby-image-wrapper {
            height: 100vh;
          }
        }

    }

    .floating {  
      animation-name: floating;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      width: calc(100% - 15px) !important;
      height: calc(100% - 20px) !important;
    }
      
    @keyframes floating {
        0% { transform: translate(5px,  0px); } 
        50%  { transform: translate(5px, 15px); }
        100%   { transform: translate(5px, -0px); }    
    }

  @media (max-width: 1440px) {
   /* background-image: url(${bg});
    /*background-position: 0 -50px;*/
   /* margin-left: 1rem;
    margin-right: 1rem;*/
    max-width: 100%;
  }

  @media (max-width: 1100px) {
   /* background-image: url(${bgMobile});
    background-position: 0 0;*/
    max-width: 100%;
  }
`;

const ContentStyles = styled.div`
 /* background: transparent;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(52px);*/
  padding: 0;
  position: relative;
  z-index: 3;

`;

export default function Layout({ children }) {
  const ref = useRef();
  const { site } = useStaticQuery(graphql`
  query {
      site {
        siteMetadata {
          links {
            link
            name
          }
        }
      }
    }
`);

useEffect(() => {
  if(typeof window != "undefined") {
    setTimeout( () => animateCSS('.lineas', 'pulse'), 1000);
  }

}, []);

const { links } = site.siteMetadata;
  return (
    <>
      <GlobalStyles />
      <Typography />

      <SiteBorderStyles>
        <div>
        {children.key == "/" &&
        <div className="parallax-container">
          <div className="img-wrapper-base">
            <StaticImage src="../assets/images/BG/bg.jpeg"
                  className=""
                  layout="fullWidth" 
                  alt="bg"
                  width={1920}
                  />
            </div>

          </div>
          }
          </div>

<ContentStyles>  
          <Nav active={children.key} logoTrack={ref} links={links}/>  
          {children}      
        </ContentStyles>
        <Footer active={children.key} links={links}/>
      </SiteBorderStyles>
    </>
  );
}
