import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { StaticImage } from "gatsby-plugin-image"

const LogoStyles = styled.div`
  /* This value controls the entire size of the logo*/

  height: 12rem;
  width: 100%;
  margin: 0;
  --borderSize: 1rem;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  padding: 0 1rem;



 

  .inner {
    height: 12rem;
    width: 12rem;

  }



  @media (max-width: 800px) {
    padding: 0;
      &.small {
      width: 8rem;
      height: 6rem;
    }

    h1 {
      &.head {
        a {
          font-size: 1.8rem;
        }    
      }
    }
  }
`;

export default React.memo(function Logo({ logoTrack, className = '' }) {

  return (
    <LogoStyles className={className}>
      <div className="inner" ref={logoTrack}>
           { className == 'small' ? <Link to="/">
              INICIO
            </Link>
           : <Link to="/">
              INICIO
            </Link>}        
      </div>
    </LogoStyles>
  );
}
)
