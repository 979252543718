import React, { useState, useEffect, useCallback  } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { HiMenu } from 'react-icons/hi';

import useOnScreen from '../utils/useOnScreen';

import Logo from './Logo';
import Menu from './Menu';
import SocialLinks from './SocialLinks';

const NavStyles = styled.nav`
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
 /* background: -webkit-radial-gradient(ellipse at center,#FFFFFF,rgb(16 13 22 / 0%) 60%,rgba(0,0,0,0) );
  background: -moz-radial-gradient(ellipse at center,#FFFFFF,rgb(16 13 22 / 0%) 60%,rgba(0,0,0,0) );
  background: radial-gradient(ellipse at center,#FFFFFF,rgb(16 13 22 / 0%) 60%,rgba(0,0,0,0) );
*/
  &:hover {
    z-index: 1000;
  }
  .logo {
    /*transform: translateY(-25%);*/
  }

  ul {
    /*background: rgb(253,187,45);*/
   /* background: linear-gradient(0deg, rgba(253,187,45,0) 35%, rgba(255,255,255,1) 60%);*/
    margin: 0;
    padding: 0 0.5rem 2rem 0.5rem;
    text-align: center;
    list-style: none;
    display: grid;
    
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    min-height: 8rem;

    &.sticked {
      grid-template-columns: repeat(5, 1fr); /*1fr 1fr auto 1fr 1fr;*/
      padding: 0 2rem;
    
        li {
          a {
              color: var(--black);
            }
          span {
            color: var(--black);
          }
          &.active {
            a {
              color: var(--light-blue);
              text-decoration: underline;
            }
            span {
              color: var(--light-blue);
              text-decoration: none;
            }
          }
        }

      svg {
          display: none;
        }

      .social {
        height: 6rem;
          a {
            display: flex;
            justify-content: center;
            align-content: center;
            color: var(--white);
            vertical-align: center;
            text-align: center;
            padding: 5px 5px 3px 5px;
            svg {
              display: block;
            }
          }
        }
    }
    svg {
          display: none;
        }
  }

  li {
    height: 5rem;
    /* --rotate: -1deg;
    transform: rotate(var(--rotate));*/
    padding: 0.5rem;
    order: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &.logo-holder {
      grid-column: 1 / span 3;
      grid-row: 1;
      display: block; 
      order: 1;
    }

    &.link {
      /*grid-column: 4;
      grid-row: 1;
      display: block;*/
      order: 2;
    }

    &.active {
      a {
        /*color: var(--light-blue);*/
        text-decoration: underline;
      }
      span {
        /*color: var(--light-blue);*/
        text-decoration: none;
      }
    }
  }
  a {
    font-size: 2rem;
    text-decoration: none;
    color: var(--white);
    display: block;
    &:hover {
      /*color: var(--eblue);*/
      text-decoration: underline;
      text-decoration-color: var(--blue);
    }

    @media (max-width: 800px) {
      font-size: 1.5rem;
    }

    /*&[aria-current='page'] {
      color: var(--eblue);
      text-decoration: underline;
      text-decoration-color: var(--eblue);
    }*/
  }

  @media (max-width: 600px) {
    --columns: 4;
    margin-bottom: 2rem;
    padding-bottom: 2rem;

    ul {
      display: none;
      justify-items: center;
      min-height: 6rem;

      .burgermenu {
        grid-column: 1 ;
        grid-row: 1;
      }

      &.sticked {
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: repeat(var(--columns), 1fr);

        padding-top: 1rem;
        svg {
          display: block;
        }

        li {
          display: none;
            &.logo-holder {
            grid-column: 2 / span 2;
            grid-row: 1 / span 2;
            display: block;
          }
        }
      }

      li {
        a {
          font-size: 1.2rem;
        }
        &.sticked, .sticked {
          display: none;
        }

        &.logo-holder {
          grid-column: 1;
          grid-row: 1 / span 2;
        }
      }
    }
    .logo-item {
      order: 0;
      grid-column: 1 / -1;
    }
    .logo {
      transform: none;
    }
  }
  @media (max-width: 500px) {
    --columns: 2;
    ul {
      &.sticked {
        grid-template-columns: repeat(4, 1fr);
        padding-top: 1rem;
      }
    }
  }
`;

export default function Nav({
  active,
  logoTrack,
  className = '',
  customStyle,
  links
}) {

  const isVisible = useOnScreen(logoTrack);
  const [menu, isMenu] = useState(false);

  const [stacked, isStacked] = useState(false);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    try {
      isStacked(!isVisible && typeof window !== 'undefined' && window.pageYOffset > 675)
    } catch (e) {
      console.log(e);
    }
  }, [isVisible, offset]);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
  }, []);

  const handleClick = useCallback(() => {
    // handle the click event
      isMenu(!menu)
  }, []);


  return (
    <NavStyles style={customStyle} className={className}>
      <ul className="sticked">
        {!isVisible && <HiMenu className="burgermenu" onClick={handleClick} />}

        {!isVisible && false && (
          <li className="logo-holder">
            <Logo className="" />
          </li>
        )}
    
    

        {links.map ( s => 
          <li key={s.name} className={active === s.link ? 'active link' : 'link'}>
            <Link  to={s.link}>{s.name}</Link>
          </li>)
        }
        <li className="link" >
          <SocialLinks className="social" />
        </li>
      </ul>
      
      
      {!isVisible && <Menu active={menu} showModal={isMenu}>
            <div className="mobileMenuContainer">
              <div className={ ''}>
                <Link to="/" >
                  Home
                </Link>
              </div>
              {links.map ( s => 
                <div key={s.name} className={active === s.link ? 'active' : ''}>
                  <Link to={s.link}>{s.name}</Link>
                </div>)
              }
            </div>
          </Menu>
      }
    </NavStyles>
  );
}
