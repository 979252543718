import React from 'react';
import styled from 'styled-components';
import { HiX } from 'react-icons/hi';

const MenuStyles = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  padding: 1rem;
  background-color: #9e9e9e69;
  .modal-btn {
    position: absolute;
    right: 4rem;
  }
  .modal-container {
    position: absolute;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20% 25%;
    * {
      z-index: 9999;
    }
    .mobileMenuContainer {
        background-color: var(--white);
        padding:1rem;
        min-height: 75vh;
        min-width: 15rem;
        div {
            margin: 1rem;
            a {
                color: var(--black);
            }
            &.active {
                a {
                    color: var(--light-blue);
                }

            }
        }
    }
  }

  @media (max-width: 800px) {
    .modal-container {
      padding: 15% 5%;
    }
  }
`;

export default function Menu({ children = {}, active = false, showModal }) {
  return (
    <>
      {active && (
        <MenuStyles>
          <HiX onClick={() => showModal(!active)} className="modal-btn" />
          <div className="modal-container" onClick={() => showModal(!active)}>
            {children}
          </div>
        </MenuStyles>
      )}
    </>
  );
}
